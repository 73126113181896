import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faWhatsapp, faFacebook, faLinkedin, faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import {
  Button, Form, FormGroup, Label, Input, FormText,
} from 'reactstrap';
import BlogArticle from '../components/Blog';
import Layout from '../layouts/index';
import Blog01 from '../images/blog/blog.jpg';
import Clients from '../components/Clients';
import DemoForm from '../components/DemoForm';
import Dashboard from '../images/500-startups/dashboard-starter.svg';
import Dashboard2 from '../images/500-startups/dashboard-starter-2.svg';
import Logo500 from '../images/500-startups/logo-500.png';
import SemiCircle from '../images/500-startups/semi-circle.svg';


function Contact({ pageContext: { locale }, location }) {

    const { t } = useTranslation()
  return (
    <Layout id="landing" locale={locale} bodyClass="page-home landing bg-grey" inverted border location={location}>
      <section className="bg-grey">
        <img src={SemiCircle} className="img-fluid position-absolute semi-circle" />
        <div className="container">
          <div className="row d-flex">
            <div className="col-lg-7 col-sm-12 align-self-center my-3">
              <h3 className="mb-5 line-height-lg">
                {t('contactPage.title')}
              </h3>
              <h3 className="font-weight-bold mb-3">{t('contactPage.highlightsTitle')}</h3>
              <ul className="list-unstyled">
                <li className="lead text-muted mb-2">
                  <FontAwesomeIcon icon={faCheckCircle} className="text-primary align-baseline icon-sm mr-1" /> {t('contactPage.highlight1')}
                </li>
                <li className="lead text-muted mb-2">
                  <FontAwesomeIcon icon={faCheckCircle} className="text-primary align-baseline icon-sm mr-1" /> {t('contactPage.highlight2')}
                </li>
                <li className="lead text-muted mb-2">
                  <FontAwesomeIcon icon={faCheckCircle} className="text-primary align-baseline icon-sm mr-1" /> {t('contactPage.highlight3')}
                </li>
                <li className="lead text-muted">
                  <FontAwesomeIcon icon={faCheckCircle} className="text-primary align-baseline icon-sm mr-1" /> {t('contactPage.highlight4')}
                </li>
              </ul>
            </div>
            <div className="col-lg-5 col-sm-12 align-self-center my-3">
              <div className="card bg-light rounded shadow-sm form-500 mx-auto">
                <div className="card-header py-2 border-bottom text-center w-100">
                  <h5 className="font-weight-bold mb-0">{t('contactPage.formTitle')}</h5>
                  <p className="text-muted mb-0">{t('contactPage.formSubtitle')}</p>
                </div>
                <div className="card-body p-3">
                  <DemoForm type="demo-500" onlyForm />
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="diagonal-cut-up bg-primary" />
        <div className="bg-primary py-0">
          <div className="container container-dashboard">
            <img src={Dashboard} className="img-fluid position-absolute dashboard-starter" />
          </div>
        </div>
        <div className="diagonal-cut-down bg-primary" />
      </section>

      <section id="clients" className="py-5">
        <div className="heading text-center mx-auto">
          <h2 className="d-inline-block">{t('contactPage.testimonial')}</h2>
        </div>
        <Clients />
      </section>

      {/* <section className="py-5 bg-light">
          <div className="container">
              <div className="bg-primary cta-container rounded">
                  <div className="row">
                      <div className="col-lg-6 col-sm-12 py-3 px-5">
                      <img src={Logo500} className="img-fluid logo-500 mb-2" />
                        <p className="text-light mb-0">Debido a que somos una empresa que participó en el programa 500 Startups y como agradecimiento al vasto conocimiento que adquirimos en el programa de aceleración, por haber sido considerado en esta gran institución, podrás usar TRATO STARTER de manera gratuita con firmas digitales blindaje 1 y además te incluimos sin costo una plantilla de Nota Convertible para levantar inversión.</p>
                      </div>
                      <div className="col-lg-6 col-sm-12 position-relative">
                          <img src={Dashboard2} className="img-fluid dashboard-cta" />
                      </div>
                  </div>
              </div>
          </div>
      </section>

            <section className="bg-light py-5">
                <div className="container">
                    <div className="text-center w-75 mx-auto mb-5">
                    <p className="lead text-muted font-weight-bold"> Además, si tienes cualquier duda sobre la transformación 100% digital de tu proceso legal, podrás tener 1 hora de consultoría sin COSTO con nuestro equipo de Legaltech.</p>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <p className="text-muted">TRATO es una solución en la nube donde podrás crear, gestionar, negociar y firmar tus contratos digitalmente utilizando 3 niveles de blindaje, ya sea asegurando tu firma con una videollamada o con el blindaje que asegura una completa trazabilidad a través del uso de blockchain.  </p>
                        </div>
                        <div className="col-lg-6">
                            <p className="text-muted">Actualmente, TRATO es utilizada por más de 5,250 usuarios (startups, bancos y corporativos) incluyendo Samsung, Estafeta, Rappi, Cabify, Homie, entre muchos otros.</p>
                        </div>
                    </div>
                </div>
            </section> */}
    </Layout>


  );
}

export default Contact;
